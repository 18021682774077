import { useState } from 'react';

export default function useDatePicker(rangeInit: boolean = true) {
    const [dateRange, setDateRange] = useState<any>([rangeInit ? new Date() : null, null]);
    const [selectedDateFilter, setSelectedDateFilter] = useState<Date>(new Date());
    const [selectedDateFilterDelivery, setSelectedDateFilterDelivery] = useState<Date>(new Date());
    const [selectedDateAdd, setSelectedDateAdd] = useState<Date>(new Date());
    const [selectedDateAddDelivery, setSelectedDateAddDelivery] = useState<Date>(new Date());
    const [selectedDateAddDeliveryDesigner, setSelectedDateAddDeliveryDesigner] = useState<Date>(new Date());
    const onDateChangeFilter = (date: Date) => {
        if (date) setSelectedDateFilter(date);
    };
    const onDateChangeFilterDelivery = (date: Date) => {
        if (date) setSelectedDateFilterDelivery(date);
    };
    const onDateChangeAdd = (date: Date) => {
        if (date) setSelectedDateAdd(date);
    };
    const onDateChangeAddDelivery = (date: Date) => {
        if (date) setSelectedDateAddDelivery(date);
    };
    const onDateChangeAddDeliveryDesigner = (date: Date) => {
        if (date) setSelectedDateAddDeliveryDesigner(date);
    };
    const onDateChangeRange = (dateRange: any) => {
        if (dateRange) setDateRange(dateRange);
    };
    const resetDateRange = () => {
        setDateRange([rangeInit ? new Date() : null, null]);
    };
    return {
        dateRange,
        selectedDateFilter,
        selectedDateAdd,
        selectedDateAddDelivery,
        selectedDateAddDeliveryDesigner,
        selectedDateFilterDelivery,
        onDateChangeFilter,
        onDateChangeAdd,
        onDateChangeAddDelivery,
        onDateChangeAddDeliveryDesigner,
        onDateChangeFilterDelivery,
        onDateChangeRange,
        resetDateRange,
    };
}
